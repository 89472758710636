import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import styled from "styled-components"
import { B1White, colors, Nav1White, fontSizer } from "../styles/helpers"
import SocialMedia from "./Footer/SocialMedia"
import MailChimpForm from "./Forms/MailchimpForm"

const getData = graphql`
  {
    quickLinks: wpMenu(name: { eq: "Quick Links Menu" }) {
      name
      menuItems {
        nodes {
          label
          url
          parentDatabaseId
          parentId
          id
          databaseId
        }
      }
    }

    mailchimpIntro: wp {
      siteWideSettings {
        acfSiteWideSettings {
          footerMailchimpContent
        }
      }
    }
  }
`

const Footer = () => {
  const data = useStaticQuery(getData)
  const { quickLinks, mailchimpIntro } = data
  return (
    <FooterStyled>
      <div className="wrapper">
        <div className="footerLinks">
          <div className="footerLinks__inner">
            <div className="footerLinks__social">
              <div className="footerLinks__social--nav">
                <h3>Follow Us</h3>
              </div>
              <div className="footerLinks__social--links">
                <SocialMedia />
              </div>
            </div>
            <div className="footerLinks__involved">
              <div className="footerLinks__involved--header">
                <h3>Quick Links</h3>
              </div>
              <div className="footerLinks__involved--nav">
                <nav>
                  <ul>
                    {quickLinks.menuItems.nodes.map(item => {
                      const slug = item.url
                        .split("/")
                        .filter(item => item !== "")
                        .join("/")
                      return (
                        <li key={item.id}>
                          <Link to={`/${slug}`}>{item.label}</Link>
                        </li>
                      )
                    })}
                  </ul>
                </nav>
              </div>
            </div>
            <div className="footerLinks__copy">
              <nav role="navigation" aria-label="Quick Links">
                <ul>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>{" "}
                    <span>&#124;</span>
                  </li>

                  <li>
                    <Link to="/disclaimer">Disclaimer</Link>
                  </li>
                </ul>
              </nav>
              <div className="footerLinks__copy--meta">
                <p>
                  Calgary Adapted Hub © {new Date().getFullYear()} All Rights
                  Reserved. Designed, and developed by{" "}
                  <a
                    title="Switchback Creative - Link will open in new window"
                    target="_blank"
                    rel="noreferrer"
                    href="https://switchbackcreative.ca"
                  >
                    Switchback Creative
                  </a>
                  , Built with ♡ and
                  {` `}
                  <a
                    title="Gatsby JS - Link will open in new window"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.gatsbyjs.com"
                  >
                    Gatsby
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="footerMailing">
          <div className="footerMailing__inner">
            <div className="footerMailing__title">
              <h3>Join our mailing list</h3>
            </div>
            <div className="footerMailing__content">
              <p>
                {
                  mailchimpIntro.siteWideSettings.acfSiteWideSettings
                    .footerMailchimpContent
                }
              </p>
            </div>
            <div className="footerMailing__form">
              <MailChimpForm />
            </div>
          </div>
        </div>
      </div>
    </FooterStyled>
  )
}

const FooterStyled = styled.footer`
  background: linear-gradient(to right, #707070 50%, #003b49 50%);
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }

  .footerLinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    background-color: #707070;
    width: 100%;

    @media (min-width: 768px) {
      width: calc(50vw);
    }

    &__inner {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      max-width: 80rem;
      padding: 2rem;

      h3 {
        ${Nav1White};
        padding-bottom: 0.5rem;
        text-transform: uppercase;
        border-bottom: 0.25rem solid ${colors.colorTertiary};
      }
    }

    &__copy {
      width: 100%;
      padding-top: 2.5rem;

      @media (min-width: 768px) {
        padding-top: 15rem;
      }

      nav {
        width: 100%;
        ul {
          display: flex;
          li {
            a {
              ${Nav1White};
              padding: 1rem;
              text-transform: uppercase;
            }
          }

          li:first-of-type {
            a {
              padding-left: 0;
            }
          }

          span {
            color: ${colors.white};
          }
        }
      }

      &--meta {
        margin-top: 0.75rem;
        p {
          ${Nav1White};
          ${fontSizer(1.2, 1.2, 76.8, 150, 1.4)};
          margin: 0;
          line-height: 1.45;

          &:hover {
            color: ${colors.white};
            cursor: initial;
          }

          a {
            ${Nav1White};
            ${fontSizer(1.2, 1.2, 76.8, 150, 1.4)};
          }
        }
      }
    }

    &__social {
      width: 100%;

      @media (min-width: 768px) {
        width: calc(50% - 1rem);
      }
    }

    &__involved {
      width: 100%;

      @media (min-width: 768px) {
        width: calc(50% - 1rem);
      }

      &--nav {
        width: 100%;

        li {
          a {
            ${Nav1White};
            text-transform: uppercase;
          }
        }
      }
    }

    &__quick {
      width: 100%;

      @media (min-width: 768px) {
        width: calc(30% - 1rem);
      }

      &--nav {
        width: 100%;

        li {
          a {
            ${Nav1White};
            text-transform: uppercase;
          }
        }
      }
    }
  }

  .footerMailing {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    background-color: #003b49;
    width: 100%;

    @media (min-width: 768px) {
      width: calc(50vw);
    }

    &__inner {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      max-width: 70rem;
      padding: 2rem;
    }

    &__title {
      width: 100%;

      h3 {
        ${Nav1White};
        margin-bottom: 1.5rem;
        text-transform: uppercase;
      }
    }

    &__content {
      width: 100%;

      p {
        ${B1White};
        margin: 0;
      }
    }

    &__form {
      width: 100%;
    }
  }
`

export default Footer
