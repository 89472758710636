import * as React from "react"
import styled, { ThemeProvider } from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import theme from "../styles/theme/Theme"
import GlobalStyle from "../styles/global/Golbal"
import Header from "./Header"
import Footer from "./Footer"
import { Btn1Gold } from "../styles/helpers"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <SkipToMain href="#main">Skip to main content</SkipToMain>
        <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
        <main id="main" role="main">
          {children}
        </main>
        <Footer />
      </ThemeProvider>
    </>
  )
}

const SkipToMain = styled.a`
  ${Btn1Gold};
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  margin-top: 2rem;
  margin-left: 2rem;
  word-wrap: normal;

  &:focus {
    position: static !important;
    overflow: visible;
    clip: auto;
    width: auto;
    height: auto;
  }
`

export default Layout
