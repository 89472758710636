import React, { useState, useEffect } from "react"
import styled from "styled-components"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { B1White, Btn1Gold, colors, Nav1Blue } from "../../styles/helpers"

import FormSuccess from "./formModals/FormSuccess"
import FormSubmit from "./formModals/FormSubmit"
import FormErrors from "./formModals/FormErrors"

const MailchimpForm = ({ idDifferernt }) => {
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [formData, setFormData] = useState({
    EMAIL: "",
    FNAME: "",
    LNAME: "",
    skill: "",
  })

  const [formStatus, setFormStatus] = useState({
    submitting: false,
    errorWarnDisplay: false,
    success: false,
    errorMsg: "",
  })

  const handleOnChange = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    })
  }

  const handleOnSubmit = event => {
    event.preventDefault()
    if (formData.skill !== "7") return

    setFormStatus({
      ...formStatus,
      submitting: true,
    })

    const listFields = {}
    listFields.FNAME = formData.FNAME
    listFields.LNAME = formData.LNAME

    addToMailchimp(formData.EMAIL, listFields)
      .then(data => {
        console.log(data)
        setTimeout(() => {
          if (data.result !== "error") {
            emailSentSuccess(data.msg)
          } else {
            formHasErrors(data.msg)
          }
        }, 2000)
      })
      .catch(err => console.log(err))
  }

  const emailSentSuccess = msg => {
    setFormStatus({
      ...formStatus,
      submitting: false,
      success: true,
    })
  }

  const formHasErrors = msg => {
    setFormStatus({
      ...formStatus,
      submitting: false,
      errorWarnDisplay: true,
      errorMsg: msg,
    })
  }

  const handleErrorModalClose = () => {
    setFormStatus({
      ...formStatus,
      submitting: false,
      errorWarnDisplay: false,
      success: false,
    })
  }

  const handleSuccessModalClose = () => {
    setFormData({
      EMAIL: "",
      FNAME: "",
      LNAME: "",
      skill: "",
    })

    setFormStatus({
      submitting: false,
      errorWarnDisplay: false,
      success: false,
      errorMsg: "",
    })
  }

  useEffect(() => {
    if (
      formData.EMAIL !== "" &&
      formData.FNAME !== "" &&
      formData.LNAME !== "" &&
      formData.skill === "7"
    ) {
      setButtonDisabled(false)
    } else {
      setButtonDisabled(true)
    }
  }, [formData])

  return (
    <>
      <Form
        id={idDifferernt ? "mailChimpForm" : "mc-embedded-subscribe-form"}
        name="mc-embedded-subscribe-form"
        className="validate"
        onSubmit={handleOnSubmit}
      >
        <div className="indicatesRequired">
          {formStatus.errorMsg !== "" && (
            <p
              className="mc-error-message"
              dangerouslySetInnerHTML={{ __html: formStatus.errorMsg }}
            />
          )}
        </div>

        <div className="fieldsWrapper">
          <InputField>
            <label htmlFor={idDifferernt ? "mailChimpEmail" : "mce-EMAIL"}>
              Email Address <span className="asterisk">* required</span>
              <input
                type="email"
                name="EMAIL"
                id={idDifferernt ? "mailChimpEmail" : "mce-EMAIL"}
                onChange={handleOnChange}
                value={formData.EMAIL}
                required
              />
            </label>
          </InputField>

          <InputFieldHalf>
            <label htmlFor={idDifferernt ? "mailChimpFirstName" : "mce-FNAME"}>
              First Name <span className="asterisk">* required</span>
              <input
                type="text"
                name="FNAME"
                id={idDifferernt ? "mailChimpFirstName" : "mce-FNAME"}
                onChange={handleOnChange}
                value={formData.FNAME}
                required
              />
            </label>
          </InputFieldHalf>

          <InputFieldHalf>
            <label htmlFor={idDifferernt ? "mailChimpLastName" : "mce-LNAME"}>
              Last Name <span className="asterisk">* required</span>
              <input
                type="text"
                name="LNAME"
                className=""
                id={idDifferernt ? "mailChimpLastName" : "mce-LNAME"}
                onChange={handleOnChange}
                value={formData.LNAME}
                required
              />
            </label>
          </InputFieldHalf>

          <InputFieldHalf>
            <label htmlFor={idDifferernt ? "mailChimpSkill" : "skill"}>
              What is 3 + 4? <span className="asterisk">* required</span>
              <input
                type="text"
                name="skill"
                id={idDifferernt ? "mailChimpSkill" : "skill"}
                onChange={handleOnChange}
                value={formData.skill}
                required
              />
            </label>
          </InputFieldHalf>
        </div>

        {/* <div id="mce-responses">
          <div
            className="response"
            id="mce-error-response"
            style={{ display: "none" }}
          ></div>
          <div
            className="response"
            id="mce-success-response"
            style={{ display: "none" }}
          ></div>
        </div> */}

        {/* <div
          style={{ position: "absolute", left: "-5000px" }}
          aria-hidden="true"
        >
          <input
            type="text"
            name="b_e1eca85774731a2532e073c97_5c07ee19ee"
            tabIndex="-1"
            value=""
            readOnly
          />
        </div> */}

        <SubmitButton>
          <button type="submit" disabled={buttonDisabled}>
            Subscribe
          </button>
        </SubmitButton>
      </Form>
      <FormSubmit isActive={formStatus.submitting} />
      <FormSuccess
        isActive={formStatus.success}
        handleClose={handleSuccessModalClose}
      />
      <FormErrors
        isActive={formStatus.errorWarnDisplay}
        handleClose={handleErrorModalClose}
      />
    </>
  )
}

const Form = styled.form`
  width: 100%;
  margin-bottom: 2.5rem;

  p {
    ${B1White};
    margin-bottom: 0;
  }

  .mc-error-message {
    color: ${colors.colorAccent};

    a {
      ${B1White};
      color: ${colors.colorTertiary};
    }
  }

  .indicatesRequired {
  }

  .fieldsWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`

const InputField = styled.div`
  width: 100%;
  margin: 1rem auto;
  padding: 1rem 0;

  label {
    ${Nav1Blue};
    display: block;
    width: 100%;
    color: ${colors.white};
    line-height: 1.5;

    &:hover {
      color: ${colors.white};
      cursor: initial;
    }

    .error-message {
      display: none;
    }

    .error-active {
      display: inline-block;
      color: red;
      padding-left: 2rem;
    }

    input {
      display: block;
      margin-top: 0.25rem;
      margin-bottom: 0.5rem;
      padding: 0.9rem 1rem;
      border-radius: 0.2rem;
      color: #444;
      margin-left: 0;
      margin-right: 0;
      width: 100%;
      border: 0.1rem ${colors.colorSecondary} solid;
    }
  }
`

const InputFieldHalf = styled.div`
  width: calc(47.5%);
  margin: 0.5rem 0;
  padding: 0.5rem 0;

  label {
    ${Nav1Blue};
    display: block;
    width: 100%;
    color: ${colors.white};
    line-height: 1.5;

    &:hover {
      color: ${colors.white};
      cursor: initial;
    }

    .error-message {
      display: none;
    }

    .error-active {
      display: inline-block;
      color: red;
      padding-left: 2rem;
    }

    input {
      display: block;
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;
      padding: 0.9rem 1rem;
      border-radius: 0.2rem;
      color: #444;
      margin-left: 0;
      margin-right: 0;
      width: 100%;
      border: 0.1rem ${colors.colorSecondary} solid;
    }
  }
`

const SubmitButton = styled.div`
  button {
    ${Btn1Gold};
  }
`

export default MailchimpForm
