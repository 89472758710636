const themeColors = {
  colorPrimary: "#003B49",
  colorSecondary: "#62A7C4",
  colorTertiary: "#F9A11B",
  colorAccent: "#E1251B",
  colorShad: "#E0DEE1",
  colorAlt: "#090b10",
  white: "rgba(245,245,245, 1)",
  grey: "rgba(226,232,233, 1)",
  greyMed: "rgba(120,119,124, 1)",
  greyBrown: "rgba(67,67,67, 1)",
  black: "rgba(0,0,0, 1)",
  strongred: "rgba(255, 0, 0, 1)",
}

export default themeColors
